import { CellProps, Column } from "react-table";
import { NotificationTableEntry } from "./NotificationsTable";
import styles from "./NotificationsTable.module.scss";
import { GetNotificationsResult, NotificationsSortFilterParamName } from "../../services/notification.service";
import { FormattedMessage } from "react-intl";
import CheckboxInput from "../CheckboxInput/CheckboxInput";
import SortingHeaderContainer from "../SortingHeaderContainer/SortingHeaderContainer";
import { NOTIFICATIONS_CONTEXT } from "../../context/QueryParamsContext/queryParamContextObjects";
import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { getFrontDtFormat, dateUtils } from "../../util/date.util";
import { LoggedUser } from "../../context/AuthContext";
import { Constants } from "../../constants";
import { RuleWithMoreData } from "../../api/data-contracts";

export function queryResultToTableData(notifications: GetNotificationsResult) {
  return {
    pageCount: notifications.pageCount,
    totalCount: notifications.totalCount,
    data: rulesEntries(notifications.data || [])
  };
}

function rulesEntries(rules: RuleWithMoreData[]): NotificationTableEntry[] {
  return rules.map(
    (msg): NotificationTableEntry => {
      return {
       Id: msg.Id,
       name: msg.name,
       description: msg.description,
       ruleNotificationTypes: msg.ruleNotificationTypes,
       ruleSchedules: msg.ruleSchedules,
       createdDateTime: msg.createdDateTime,
       createdUserName: msg.createdUserName,

      }
    });
}

export function generateNotificationsColumns(loggedUser: LoggedUser | undefined): Column<NotificationTableEntry>[] {
  return [
    {
      id: "checkbox",
      Cell: ({ row }: CellProps<NotificationTableEntry>) => (
        <CheckboxInput
          checked={row.isSelected ? "FULL" : "EMPTY"}
          onClick={() => row.toggleRowSelected(!row.isSelected)}
        />
      ),
    },
    {
      accessor: "name",
      Header: (
        <SortingHeaderContainer<NotificationsSortFilterParamName> context={NOTIFICATIONS_CONTEXT} paramName="name">
          <FormattedMessage id="NAME" defaultMessage="Name" />
        </SortingHeaderContainer>
      ),
      Cell: ({ value }: CellProps<NotificationTableEntry>) => {
        return <OverlayTrigger
          placement="bottom"
          offset={[30, 0]}
          overlay={
            <Tooltip>{value}</Tooltip>}>
          <div className={styles["schedule"]}>{value}</div>
        </OverlayTrigger>
      },
    },
    {
      accessor: "description",
      Header: (
        <SortingHeaderContainer<NotificationsSortFilterParamName> context={NOTIFICATIONS_CONTEXT} paramName="description">
          <FormattedMessage id="NOTIFICATIONS_TABLE.DESCRIPTION" defaultMessage="Description" />
        </SortingHeaderContainer>
      ),
      Cell: ({ value }: CellProps<NotificationTableEntry>) => {
        return <OverlayTrigger
          placement="bottom"
          offset={[30, 0]}
          overlay={
            <Tooltip>{value}</Tooltip>}>
          <div className={styles["schedule"]}>{value}</div>
        </OverlayTrigger>
      },
    },
    {
      accessor: "ruleSchedules",
      Header: (
        <FormattedMessage id="NOTIFICATIONS_TABLE.SCHEDULE" defaultMessage="Schedule" />
      ),
      Cell: ({ value }: CellProps<NotificationTableEntry>) => {
        return <OverlayTrigger
          placement="bottom"
          offset={[30, 0]}
          overlay={
            <Tooltip>
              {value && value.length > 0 &&
                <Row>
                  <Col xl="4" style={{ color: "#B5B5B5", marginBottom: "5px", textAlign: "left" }}>
                    Day
                  </Col>
                  <Col xl="8" style={{ color: "#B5B5B5", marginBottom: "5px", textAlign: "left" }}>
                    Schedule
                  </Col>
                  {
                    value.map((x: any) => (
                      <>
                        <Col xl="4" style={{ textAlign: "left" }}>
                          {x.weekDay.toString().substring(0, 3)}
                        </Col>
                        <Col xl="8" style={{ textAlign: "left" }}>
                          {x.fromTime.toString().substring(0, 5)}-{x.toTime.toString().substring(0, 5)}
                        </Col>
                      </>
                    ))

                  }
                </Row>
              }

            </Tooltip>
          }
        >
          <div className={styles["schedule"]}>{value && value.length > 0 ? value.map((x: any) => `${x.weekDay.toString().substring(0, 3)}, ${x.fromTime.toString().substring(0, 5)}-${x.toTime.toString().substring(0, 5)} `).toString() : null}</div>

        </OverlayTrigger>;
      },
    },
    {
      accessor: "ruleNotificationTypes",
      Header: (
          <FormattedMessage id="NOTIFICATIONS_TABLE.NOTIFYBY" defaultMessage="Notify By" />
      ),
      Cell: ({ value }: CellProps<NotificationTableEntry>) => {
        return <div>{value && value.length > 0 ? value.map((x: any) => x.notification_type).toString() : null}</div>;
      },
    },
    {
      accessor: "createdDateTime",
      Header: (
        <SortingHeaderContainer<NotificationsSortFilterParamName> context={NOTIFICATIONS_CONTEXT} paramName="createdDateTime">
          <FormattedMessage id="NOTIFICATIONS_TABLE.CREATED" defaultMessage="Created" />
        </SortingHeaderContainer>
      ),
      Cell: ({ value }: CellProps<NotificationTableEntry>) => {
        return !value ? null : <div>{dateUtils.getFormattedLocalDate(value, getFrontDtFormat(loggedUser?.profile?.dateTimeFormat)?.timeDate || Constants.DEFAULT_FRONT_DT_FORMAT.timeDate)}</div>;
      },
    },
    {
      accessor: "createdUserName",
      Header: (
        <SortingHeaderContainer<NotificationsSortFilterParamName> context={NOTIFICATIONS_CONTEXT} paramName="createdUserName">
          <FormattedMessage id="NOTIFICATIONS_TABLE.CREATEDBY" defaultMessage="Created By" />
        </SortingHeaderContainer>
      ),
      Cell: ({ value }: CellProps<NotificationTableEntry>) => {
        return <div>{value}</div>;
      },
    },
  ];
}
