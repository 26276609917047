import { useMutation, useQueryClient } from "@tanstack/react-query";
import { RuleDto } from "../api/data-contracts";
import { useToastContext } from "../context/ToastContext";
import { addRuleMutation, deleteRuleMutation, updateRuleMutation } from "../mutations/rule.mutation";

interface UseRulesProps {
  onSuccessAddRule?: () => void;
  onSuccessUpdateRule?: () => void;
}

function useRule({
  onSuccessAddRule,
  onSuccessUpdateRule
}: UseRulesProps = {}) {
  const { add: addToast } = useToastContext();
  const queryClient = useQueryClient();
  const { mutate: mutateAddRule, isLoading, isError } = useMutation(addRuleMutation());
  const { mutate: mutateUpdateRule, isLoading: isUpdateLoading, isError: isUpdateError } = useMutation(updateRuleMutation());
  const { mutate: mutateDeleteUser, isLoading: isDeleteLoading, isError: isDeleteError } = useMutation(deleteRuleMutation());

  async function addRule(rule: RuleDto): Promise<void> {
    mutateAddRule(
      { rule },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: ["notifications"] });
          addToast({ type: "POSITIVE", content: `A new notification rule was successfully added.` });
          onSuccessAddRule?.();
        },
        onError: (error) => {
          let { response } = error;
          let data = response?.data as any;
          let message = data?.Exception?.Message;
          addToast({ type: "NEGATIVE", content: `Failed to add a new notificaiton rule. ${message ? message : ""}` });
        },
      }
    );
  }

  async function updateRule(rule: RuleDto, ruleId: number): Promise<void> {
    mutateUpdateRule(
      { rule, ruleId },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: ["notifications"] });
          addToast({ type: "POSITIVE", content: `Notification rule saved successfully` });
          onSuccessUpdateRule?.();
        },
        onError: () => {
          addToast({ type: "NEGATIVE", content: `Failed to update a notificaiton rule.` });
        },
      }
    );
  }

  async function deleteRule(ruleId: number): Promise<void> {
    mutateDeleteUser(
      { ruleId },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: ["notifications"] });
          addToast({ type: "POSITIVE", content: `The selected notifications were successfully deleted` });
          onSuccessUpdateRule?.();
        },
        onError: () => {
          addToast({ type: "NEGATIVE", content: `Failed to delete the selected notificaiton rule.` });
        },
      }
    );
  }


  return {
    addRule,
    updateRule,
    isLoading,
    isError,
    isUpdateLoading,
    isUpdateError,
    deleteRule,
    isDeleteLoading,
    isDeleteError
  };
}

export default useRule;
