import { Row, TableInstance } from "react-table";
import { EventMessage, MediaSource, MediaType } from "../../api/data-contracts";
import { GetEventsResult } from "../../services/events.service";
import { EventsTableEntry, MediaDetails } from "./EventsTable";

export function messageToMediaDetails(msg: EventMessage): MediaDetails[] {
  const mediaArr = [];
  for (let i = 0; i < (msg.cabinImageCount || 0); ++i) {
    mediaArr.push({
      type: MediaType.Image,
      source: MediaSource.CABIN,
      index: i,
    });
  }
  for (let i = 0; i < (msg.cabinVideoCount || 0); ++i) {
    mediaArr.push({
      type: MediaType.Video,
      source: MediaSource.CABIN,
      index: i,
    });
  }
  for (let i = 0; i < (msg.roadImageCount || 0); ++i) {
    mediaArr.push({
      type: MediaType.Image,
      source: MediaSource.ROAD,
      index: i,
    });
  }
  for (let i = 0; i < (msg.roadVideoCount || 0); ++i) {
    mediaArr.push({
      type: MediaType.Video,
      source: MediaSource.ROAD,
      index: i,
    });
  }
  return mediaArr;
}

function messagesToEventEntries(messages: EventMessage[]): EventsTableEntry[] {
  return messages.map(
    (msg): EventsTableEntry => ({
      vehicleType: msg.vehicleType,
      vehicleId: msg.vehicleId,
      driverId: msg.driverID,
      type: msg.eventType,
      mediaArr: messageToMediaDetails(msg),
      deviceId: msg.deviceID,
      messageId: msg.messageID,
      dateTime: msg.eventDateTime,
      speed: msg.speed,
      tripId: msg.tripID,
      locationAddr: msg.lastKnownStreetAddress,
      eventAttr1 : msg.eventAttr1,
      notificationType:msg.notificationType
    })
  );
}

export function queryResultToTableData(queryResult: GetEventsResult) {
  return {
    pageCount: queryResult?.pageCount,
    totalCount: queryResult.totalCount,
    data: messagesToEventEntries(queryResult?.data || []),
  };
}

function isExpandable(row: Row<EventsTableEntry>) {
  const values = row.original;
  return values.deviceId && values.messageId && values.mediaArr.length;
}

export function onClickRow(row: Row<EventsTableEntry>, table: TableInstance<EventsTableEntry>) {
  const isRowExpanded = row.isExpanded;
  table.toggleAllRowsExpanded(false);
  if (!isRowExpanded && isExpandable(row)) {
    row.toggleRowExpanded(true);
  }

  const isRowSelected = row.isSelected;
  table.toggleAllRowsSelected(false);
  if (!isRowSelected) {
    row.toggleRowSelected(true);
  }
}
