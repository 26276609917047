import { useQuery } from "@tanstack/react-query";
import { GetNotificationsResult } from "../services/notification.service";
import { ServiceGetResult } from "../models/requests/shared.request";
import { useNotificationsQueryParamsContext } from "../context/QueryParamsContext/NotificationsQueryParamsContextProvider";
import { useEffect } from "react";
import { notificationsQuery } from "../queries/notification.query";

function useQueryNotifications<selectedT extends ServiceGetResult<unknown>>(
  selector: (queryResult: GetNotificationsResult) => selectedT
) {
  
  const { queryParams, setPageIndex } = useNotificationsQueryParamsContext();
  const { data, isLoading, isError, isFetching } = useQuery(notificationsQuery(queryParams, selector, true));

  useEffect(() => {
    const pageCount = data?.pageCount;
    if (!isFetching && pageCount && queryParams.paging.pageIndex > pageCount) {
      setPageIndex(pageCount);
    }
  }, [data, isFetching, queryParams, setPageIndex]);

  return {
    data: data,
    isError,
    isLoading: isLoading && isFetching,
    isFetching,
  };
}

export default useQueryNotifications;
