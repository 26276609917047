import { createQueryParamContext } from "./shared";

export const DEVICES_CONTEXT = createQueryParamContext();
export const DRIVERS_CONTEXT = createQueryParamContext();
export const EVENTS_CONTEXT = createQueryParamContext();
export const USERS_CONTEXT = createQueryParamContext();
export const CONFIGS_CONTEXT = createQueryParamContext();
export const BILLING_CONTEXT = createQueryParamContext();
export const TRIPS_CONTEXT = createQueryParamContext();
export const NOTIFICATIONS_CONTEXT = createQueryParamContext();






