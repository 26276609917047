import { FormattedMessage } from "react-intl";
import NestedModal from "../../NestedModal/NestedModal";
import { DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import ModalMainButton from "../../Buttons/ModalMainButton/ModalMainButton";
import ModalSecondaryButton from "../../Buttons/ModalSecondaryButton/ModalSeconaryButton";
import styles from "../NotificationsTable.module.scss";


type ConfirmFormChangeModalProps = {
    show: boolean;
    onClickYes: () => void;
    onClickNo: () => void;
};

function ConfirmFormChangeModal({ show, onClickNo, onClickYes }: ConfirmFormChangeModalProps) {
    return (
        <NestedModal
            show={show}
            onClickNo={onClickNo}
            body={
                <>
                    <DialogTitle id="alert-dialog-title">
                        <FormattedMessage id="CONFIRM" defaultMessage="Confirm" />
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <FormattedMessage id="CHANGE_FORM_CONFIRM" defaultMessage="Are you sure you want to discard the changes?" />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <ModalSecondaryButton className={styles["button"]} type="button" onClick={onClickNo}>
                            <FormattedMessage id="AddUserForm.NO" defaultMessage="No" />
                        </ModalSecondaryButton>
                        <ModalMainButton onClick={onClickYes} className={styles["button"]} type="button" autoFocus>
                            <FormattedMessage id="AddUserForm.YES" defaultMessage="Yes" />
                        </ModalMainButton>
                    </DialogActions>
                </>
            }
        />
    );
}

export default ConfirmFormChangeModal;
