import styles from "./AddEditNotificationModal.module.scss";
import { FormattedMessage } from "react-intl";
import { Col, Row } from "react-bootstrap";
import FormInput from "../../../form/FormInput/FormInput";
import { DemoItem } from '@mui/x-date-pickers/internals/demo';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { Controller } from "react-hook-form";
import dayjs from "dayjs";

interface INotificationsScheduleProps {
    form: any;
    item: any;
    index: any;
}

function NotificationsSchedule({ form, item, index }: INotificationsScheduleProps) {
    const { control, watch, formState: { errors } } = form;
    const watchSchedules = watch(`ruleSchedules`);
    return (
        <Row key={item.id} className={`${styles["schedule-item"]} pe-2 mt-2 pb-4 align-items-center ms-2`}>
            <Col xl={3}>
                <Controller
                    render={({ field: { onChange, value, name } }) => <FormInput
                        type="checkbox"
                        name={name}
                        className="box"
                        input={{ label: <span className={`${styles["schedule-name"]} mt-1`}>{item.label}</span>, onChange, value: value }}
                        control={control}
                    />}
                    name={`ruleSchedules.${index}.selected`}
                    control={control}
                />
            </Col>
            <Col className="p-1">
                <>
                    <Controller
                        render={({ field: { onChange, value } }) => <DemoItem label={<span className={styles["schedule-time-header"]}><FormattedMessage id="Time.FROM" defaultMessage="From" /></span>}>
                            <TimePicker value={value} onChange={(e) => onChange(e)} className={styles["timePicker"]} views={['hours', 'minutes']} />
                        </DemoItem>}
                        name={`ruleSchedules.${index}.fromTime`}
                        control={control}
                        rules={{ required: watchSchedules?.[index]?.selected }}
                    />
                    {errors.ruleSchedules?.[index]?.fromTime && <div className={styles["errorMsg"]}><FormattedMessage id="REQUIRED" defaultMessage="Required" /></div>}
                </>
            </Col>
            <Col className="p-1">
                <>
                    <Controller
                        render={({ field: { onChange, value } }) => <DemoItem label={<span className={styles["schedule-time-header"]}><FormattedMessage id="Time.TO" defaultMessage="To" /></span>}>
                            <TimePicker value={value} onChange={(e) => onChange(e)} className={styles["timePicker"]} views={['hours', 'minutes']} minTime={watchSchedules?.[index]?.fromTime ? dayjs(watchSchedules?.[index]?.fromTime) : undefined} />
                        </DemoItem>}
                        name={`ruleSchedules.${index}.toTime`}
                        control={control}
                        rules={{ required: watchSchedules?.[index]?.selected }}
                    />
                    {errors.ruleSchedules?.[index]?.toTime && <div className={styles["errorMsg"]}><FormattedMessage id="REQUIRED" defaultMessage="Required" /></div>}
                </>
            </Col>
        </Row>)
}

export default NotificationsSchedule;