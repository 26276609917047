import { FormattedMessage } from "react-intl";
import styles from "./SettingsView.module.scss";
import { Button, Col, Form, Row } from "react-bootstrap";
import { FormGroup } from "../form/FormGroup/FormGroup";
import FormInput from "../form/FormInput/FormInput";
import { useForm, useWatch } from "react-hook-form";
import { dateUtils } from "../../util/date.util";
import { StandardOption } from "../ReactSelect/ReactSelect";
import useUser from "../../hooks/useUser";
import { LoggedUser, useAuthContext } from "../../context/AuthContext";
import { DateTimeFormat as BackDtFormat, Fleet, Measurement, Role } from "../../api/data-contracts";
import { getFrontDtFormat } from "../../util/date.util";
import ModalYesNo from "../ModalYesNo/ModalYesNo";

import { useEffect, useState } from "react";
import { ReactComponent as NoticeIcon } from "../../assets/icons/NoticeIcon.svg";
import ChangePasswordModal from "./ChangePasswordModal";
import { LangNamesType, useIntlContext } from "../../context/IntlContext";
import useQueryOwnFleet from "../../hooks/useQueryOwnFleet";
import useTsp from "../../hooks/useTsp";
import useFleet from "../../hooks/useFleet";
import { Tsp, useTspFleetContext } from "../../context/TspFleetContext/TspFleetContext";
import { Constants } from "../../constants";
import TIME_ZONES, { DEFAULT_TIME_ZONE } from "../../util/timezone.util";

type SettingsFormInputs = {
  language: StandardOption;
  defaultAccountLanguage: StandardOption;
  defaultAccountMeasurement: boolean;
  isImperialSystem: boolean;
  timeZoneName?: StandardOption;
  adjustDaylightSaving?:boolean;
  format: { label: string; value: BackDtFormat };
};

type UpdateType = "USER" | "FLEET" | "TSP";

const dateTime = new Date();

function SettingsView() {
  const { getLangNames } = useIntlContext();
  const { loggedUser } = useAuthContext();
  const role = loggedUser?.role;
  const { data: ownFleet } = useQueryOwnFleet();
  const { activeTsp } = useTspFleetContext();
  const userRole = useAuthContext().loggedUser?.role;

  const updateType: UpdateType =
    role === Role.SpManager || role === Role.SpUser ? "TSP" : role === Role.FleetManager ? "FLEET" : "USER";

  const { control, handleSubmit, reset } = useForm<SettingsFormInputs>({
    defaultValues: getFormDefaults(loggedUser, activeTsp, ownFleet, getLangNames),
  });

  useEffect(() => {
    reset(getFormDefaults(loggedUser, activeTsp, ownFleet, getLangNames))
  }, [loggedUser, activeTsp, ownFleet, getLangNames, reset])
  const {  isImperialSystem, defaultAccountMeasurement } = useWatch({ control });

  const { putProfile, isLoadingPutProfile } = useUser({ onSuccessPutProfile: () => setShowValidationModal(false) });
  const { updateTspProfile, isLoadingUpdateTspProfile } = useTsp({
    onSuccessUpdateProfile: () => setShowValidationModal(false),
  });
  const { updateFleetProfile, isLoadingUpdateFleetProfile } = useFleet({
    onSuccessUpdateProfile: () => setShowValidationModal(false),
  });

  const [showValidationModal, setShowValidationModal] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);

  const isOrgSettings = updateType === "TSP" || updateType === "FLEET";
  const isLoading =
    updateType === "USER"
      ? isLoadingPutProfile
      : updateType === "FLEET"
        ? isLoadingUpdateFleetProfile
        : isLoadingUpdateTspProfile;
  const isDisabled =
    !role || (updateType === "USER" ? !loggedUser?.email : updateType === "FLEET" ? !ownFleet?.id : !activeTsp?.id);

  const onSubmit = async ({ format, isImperialSystem, language, timeZoneName, adjustDaylightSaving, defaultAccountLanguage,defaultAccountMeasurement }: SettingsFormInputs) => {
    const dtFormat = format.value;
    const measurement = isImperialSystem ? Measurement.Imperial : Measurement.Metric;
    const isoLang = language.value;
    const timeZone = timeZoneName?.value
    const fleetIsoLang = defaultAccountLanguage.value;
    const fleetMeasurement = defaultAccountMeasurement ? Measurement.Imperial : Measurement.Metric;

    if (updateType === "USER") {
      putProfile({
        userEmail: loggedUser?.email!,
        dateTimeFormat: dtFormat,
        isoLanguage: isoLang,
        measurement: measurement,
      });
    } else if (updateType === "FLEET") {
      updateFleetProfile({
        id: ownFleet?.id!,
        ...ownFleet,
        dateTimeFormat: dtFormat,
        isoLanguage: isoLang,
        measurement: measurement,
        timeZoneName:timeZone,
        adjustDaylightSaving:adjustDaylightSaving,
        defaultAccountMeasurement:fleetMeasurement,
        defaultAccountLanguage:fleetIsoLang
      });
    } else {
      updateTspProfile({
        id: activeTsp?.id!,
        dateTimeFormat: dtFormat,
        isoLanguage: isoLang,
        measurement: measurement,
      });
    }
  };

  return (
    <div className={`d-flex flex-row justify-content-center ${styles.container}`}>
      <Form className={styles["form-container"]}>
        <h3 className={styles["user-settings-header"]}>
        <FormattedMessage id="USER_SETTINGS" defaultMessage="User Settings" />
        </h3>
        <div className={styles["seperator"]}></div>
        {/* <FormattedMessage id="DATE_AND_TIME_SET" defaultMessage="Date and time set as user computer" /> */}
        <div className="d-flex flex-column gap-3 p-3">
          <Row className="align-items-center">
            <Col xs={5}>
            <FormattedMessage id="LANGUAGE" defaultMessage="Language" />
              
            </Col>
            <Col xs={7}>
              <FormGroup
                gap={false}
                label={''}
                input={
                  <FormInput
                    type="select"
                    name="language"
                    control={control}
                    rules={{ required: true }}
                    input={{
                      options: getLangNames()!.map((l) => ({ label: l.long, value: l.short })),
                      isDisabled: isLoading,
                    }}
                  />
                }
              />
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col xs={5}>
               <FormattedMessage id="DATE_FORMAT" defaultMessage="Date Format" />:
            </Col>
            <Col xs={7}>
              <FormGroup
                gap={false}
                label={''}
                input={
                  <FormInput
                    type="select"
                    name="format"
                    control={control}
                    rules={{ required: true }}
                    input={{
                      options: Object.values(BackDtFormat).map((backDt) => ({
                        value: backDt,
                        label: dateUtils.getFormattedLocalDate(dateTime, getFrontDtFormat(backDt)?.timeDate),
                      })),
                      isDisabled: isLoading,
                    }}
                  />
                }
              />
            </Col>
          </Row>
          <div className={`d-flex justify-content-between gap-2`}>
            <FormattedMessage id="MEASUREMENTS_SYSTEM" defaultMessage="Measurements System" />
            <div className={`d-flex justify-content-end gap-3`} style={{ height: "1.5rem" }}>
              <span className={styles[isImperialSystem ? "inactive" : "active"]}><FormattedMessage id="METRIC" defaultMessage="Metric" /></span>
              <FormInput type="switch" name="isImperialSystem" control={control} input={{ disabled: isLoading }} />
              <span className={styles[isImperialSystem ? "active" : "inactive"]}><FormattedMessage id="IMPERIAL" defaultMessage="Imperial" /></span>
            </div>
          </div>

               { 
                
                (userRole !== Role.FleetManager) &&
                  <>
                     <div className="d-flex align-items-center justify-content-end">
                      <Button
                        type="button"
                        className={styles["save-btn"]}
                        variant="outline-primary"
                        disabled={isLoading || isDisabled}
                        onClick={() => setShowValidationModal(true)}
                      >
                        {/* {isOrgSettings ? (
                          <FormattedMessage id="APPLY_SETTINGS_ORG" defaultMessage="Apply Settings to the all Organization" />
                        ) : ( */}
                        <FormattedMessage id="SAVE" defaultMessage="SAVE" />
                        {/* )} */}
                      </Button>
                    </div>
                  </>

              }
         
        </div>
        <h3 className={`mt-3 ${styles["user-settings-header"]}`}>
            <FormattedMessage id="SECURITY" defaultMessage="Security" />
        </h3>
        <div className={`${styles["seperator"]}`}></div>

        <div className="d-flex align-items-center gap-2 py-2">
          <Button variant="link" onClick={() => setShowPasswordModal(true)}>
            <FormattedMessage id="CHANGE_PASSWORD" defaultMessage="Change Password" />
          </Button>
        </div>
     
        <div className="d-flex flex-column gap-3 p-3">
        
          {(userRole === Role.FleetManager) &&
            <>

<h3 className={styles["user-settings-header"]}>
          <FormattedMessage id="ACCOUNT_SETTINGS" defaultMessage="Account Settings" />
        </h3>
        <div className={styles["seperator"]}></div>
        
      <Row className="align-items-center">
                  <Col xs={5}>
                  <FormattedMessage id="LANGUAGE" defaultMessage="Language" />
                  </Col>
                  <Col xs={7}>
                    <FormGroup
                      gap={false}
                      label={''}
                      input={
                        <FormInput
                          type="select"
                          name="defaultAccountLanguage"
                          control={control}
                          rules={{ required: true }}
                          input={{
                            options: getLangNames()!.map((l) => ({ label: l.long, value: l.short })),
                            isDisabled: isLoading,
                          }}
                        />
                      }
                    />
                  </Col>
                </Row>
                <Row className="align-items-center">
                  <Col xs={5}>
                  <FormattedMessage id="TIME_ZONE" defaultMessage="Time zone" />
                  </Col>
                  <Col xs={7}>
                    <FormGroup
                      gap={false}
                      label={''}
                      input={
                        <FormInput
                          type="select"
                          name="timeZoneName"
                          control={control}
                          rules={{ required: true }}
                          input={{
                            options: TIME_ZONES,
                            isDisabled: isLoading,
                          }}
                        />
                      }
                    />
                  </Col>
                </Row>
                <div className={`d-flex justify-content-between gap-2`}>
                  <FormattedMessage id="ADJUST_FOR_DAYLIGHT_SETTING_AUTOMATICALLY" defaultMessage="Adjust for daylight saving time automatically" />
                  <div className={`d-flex justify-content-end gap-3`} style={{ height: "1.5rem" }}>
                    <FormInput type="switch" name="adjustDaylightSaving" className="specialswitch" control={control} input={{ disabled: isLoading }} />
                  </div>
                </div>

                  <div className={`d-flex justify-content-between gap-2`}>
                    <FormattedMessage id="MEASUREMENTS_SYSTEM" defaultMessage="Measurements System" />
                    <div className={`d-flex justify-content-end gap-3`} style={{ height: "1.5rem" }}>
                      <span className={styles[defaultAccountMeasurement ? "inactive" : "active"]}><FormattedMessage id="METRIC" defaultMessage="Metric" /></span>
                      <FormInput type="switch" name="defaultAccountMeasurement" control={control} input={{ disabled: isLoading }} />
                      <span className={styles[defaultAccountMeasurement ? "active" : "inactive"]}><FormattedMessage id="IMPERIAL" defaultMessage="Imperial" /></span>
                    </div>
                  </div>


                { <div className="d-flex align-items-center justify-content-end my-3">
                  <div className="d-flex align-items-center justify-content-end">
                  <Button
                    type="button"
                    className={styles["save-btn"]}
                    variant="outline-primary"
                    disabled={isLoading || isDisabled}
                    onClick={() => setShowValidationModal(true)}
                  >
            {/* {isOrgSettings ? (
              <FormattedMessage id="APPLY_SETTINGS_ORG" defaultMessage="Apply Settings to the all Organization" />
            ) : ( */}
            <FormattedMessage id="SAVE" defaultMessage="SAVE" />
            {/* )} */}
          </Button>
        </div>
          </div> }
            </>
          }

          

        </div>
      </Form>

      <ModalYesNo
        show={showValidationModal}
        onClickYes={handleSubmit(onSubmit)}
        onClickNo={() => setShowValidationModal(false)}
        onHide={() => setShowValidationModal(false)}
        isLoading={isLoading}
        body={
          <div className="d-flex flex-column align-items-center gap-4">
            <NoticeIcon />
            {isOrgSettings ? (
              <FormattedMessage
                id="SETTINGS.APPLY_ALL_QUESTION"
                defaultMessage="Are you sure you want to apply this settings to your entire organization?"
              />
            ) : (
              <FormattedMessage
                id="SETTINGS.APPLY_QUESTION"
                defaultMessage="Are you sure you want to apply this settings?"
              />
            )}
          </div>
        }
      />
      <ChangePasswordModal show={showPasswordModal} handleClose={() => setShowPasswordModal(false)} />
    </div>
  );
}

export default SettingsView;

function getFormDefaults(
  user: LoggedUser | undefined,
  tsp: Tsp | undefined,
  fleet: Fleet | undefined,
  getLangNames: () => LangNamesType[]
): SettingsFormInputs {
  let profile = user?.profile;
  let role = user?.role;
  let language = undefined;
  let fleetLanguage = undefined;
  let isImperialSystem: boolean = false;
  let fleetImperialSystem: boolean = false;
  let dtFormat = undefined;
  let adjustDaylight = fleet? fleet.adjustDaylightSaving : true;

  const nowDate = new Date();

  language = {
    value: profile?.isoLanguage,
    label: getLangNames()?.find((lang) => lang.short === profile?.isoLanguage)?.long,
  };

  fleetLanguage = {
    value: fleet?.defaultLanguage,
    label: getLangNames()?.find((lang) => lang.short === fleet?.defaultLanguage)?.long,
  };

  isImperialSystem = profile?.measurement === Measurement.Imperial;

  fleetImperialSystem = fleet?.defaultMeasurement === Measurement.Imperial;

  dtFormat = {
    value: profile?.dateTimeFormat,
    label: dateUtils.getFormattedLocalDate(nowDate, getFrontDtFormat(profile?.dateTimeFormat)?.timeDate),
  };

  const currentTimezoneName = fleet?.timeZoneName?fleet?.timeZoneName : Intl.DateTimeFormat().resolvedOptions().timeZone;
  const timeZoneInfo = TIME_ZONES.find(x=>x.value === currentTimezoneName);

  

  if (!user?.profile?.dateTimeFormat) {
    switch (role) {
      case Role.SpManager:
      case Role.SpUser:
        dtFormat = {
          value: tsp?.defaultDateTimeFormat,
          label: dateUtils.getFormattedLocalDate(nowDate, getFrontDtFormat(tsp?.defaultDateTimeFormat)?.timeDate),
        };
        break;
      case Role.FleetManager:
      case Role.FleetUser:
        dtFormat = {
          value: fleet?.defaultDateTimeFormat,
          label: dateUtils.getFormattedLocalDate(nowDate, getFrontDtFormat(fleet?.defaultDateTimeFormat)?.timeDate),
        };
        break;
    }
  }

  if (!user?.profile?.isoLanguage) {
    switch (role) {
      case Role.SpManager:
      case Role.SpUser:
        language = {
          value: tsp?.defaultLanguage,
          label: getLangNames()?.find((lang) => lang.short === tsp?.defaultLanguage)?.long,
        };
        break;
      case Role.FleetManager:
      case Role.FleetUser:
        language = {
          value: fleet?.defaultLanguage,
          label: getLangNames()?.find((lang) => lang.short === fleet?.defaultLanguage)?.long,
        };
        break;
    }
  }

  if (!user?.profile?.measurement) {
    switch (role) {
      case Role.SpManager:
      case Role.SpUser:
        isImperialSystem = tsp?.defaultMeasurement === Measurement.Imperial;
        break;
      case Role.FleetManager:
      case Role.FleetUser:
        isImperialSystem = fleet?.defaultMeasurement === Measurement.Imperial;
        break;
    }
  }

  if (!language.label || !language.value) {
    language = { label: getLangNames()[0].long, value: getLangNames()![0].short };
  }
  if (!dtFormat.label || !dtFormat.value) {
    dtFormat = {
      label: dateUtils.getFormattedLocalDate(nowDate, Constants.DEFAULT_FRONT_DT_FORMAT.timeDate),
      value: Constants.DEFAULT_BACK_DT_FORMAT,
    };
  }

  return {
    language: { label: language.label!, value: language.value! },
    isImperialSystem,
    format: { label: dtFormat.label, value: dtFormat.value! },
    timeZoneName :timeZoneInfo ?? DEFAULT_TIME_ZONE,
    adjustDaylightSaving:adjustDaylight,
    defaultAccountMeasurement:fleetImperialSystem,
    defaultAccountLanguage: { label: fleetLanguage.label!, value: fleetLanguage.value! },
  };
}
