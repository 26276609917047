import { QueryFunctionContext } from "@tanstack/react-query";
import { GetNotificationsParams, GetNotificationsResult, notificationService } from "../services/notification.service";

export function notificationsQuery<selectedT>(
  params: GetNotificationsParams,
  selector: (queryResult: GetNotificationsResult) => selectedT,
  enabled: boolean
) {
  return {
    queryKey: ["notifications", params] as const,
    queryFn: async (context: QueryFunctionContext<readonly ["notifications", GetNotificationsParams]>) =>
      await notificationService.getNotifications(context.queryKey[1]),
    select: selector,
    enabled: enabled,
    retry: false
  };
}

export function notificationQuery(id: number, enabled: boolean) {
  return {
    queryKey: ["notification", id] as const,
    queryFn: async (context: QueryFunctionContext<readonly ["notification", number]>) =>
      await notificationService.getNotification(context.queryKey[1]),
    staleTime: Infinity,
    enabled: enabled,
  };
}
