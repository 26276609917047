import { getEventMetadata } from "../util/events.util";
import GenericEvent from "../assets/events/GenericEvent.svg";

type EventIconProps = {
  serverName?: string;
  size?: "small" | "large";
  width?: "48px" | "24px";
};

function EventIcon({ serverName, size = "large", width = "48px" }: EventIconProps) {
  let img = undefined;
  if (serverName) {
    img = getEventMetadata(serverName)?.img;
  }
  if (!img) img = GenericEvent;

  return <img src={img} alt="event" style={{ width: width, transform: size === "small" ? "scale(0.5)" : undefined }} />;
}

export default EventIcon;
