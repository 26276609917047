import styles from "./NotificationsTableController.module.css";
import { FormattedMessage, useIntl } from "react-intl";
import addIconPath from "../../assets/icons/Add.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/Edit.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icons/Close.svg";
import { Color, InputUi } from "../../constants";
import ActionButton from "../Buttons/ActionButton/ActionButton";
import { useMemo } from "react";
import { useTspFleetContext } from "../../context/TspFleetContext/TspFleetContext";
import { NOTIFICATIONS_CONTEXT } from "../../context/QueryParamsContext/queryParamContextObjects";
import { NotificationsSortFilterParamName } from "../../services/notification.service";
import { useAuthContext } from "../../context/AuthContext";
import { Role } from "../../api/data-contracts";
import SingleSelectFilter from "../Filters/SingleSelectFilter";

interface NotificationsControllerProps {
  onClickAdd: () => void;
  onClickEdit?: () => void;
  onClickDelete?: () => void;
  notificationsCount?: number;
  disable?: {
    add?: boolean;
    edit?: boolean;
    delete?: boolean;
  };
}

function NotificationsTableController({
  onClickAdd,
  onClickEdit,
  onClickDelete,
  notificationsCount,
  disable,
}: NotificationsControllerProps) {

  const { activeFleets, allTsps } = useTspFleetContext();
  const { formatMessage } = useIntl();

  const userRole = useAuthContext().loggedUser?.role;

  const fleetsOptions = useMemo(() => {
    return activeFleets.map(fleet => ({ value: fleet.id!.toString(), label: fleet.companyFriendlyName! }))
  }, [activeFleets]);

  const tspOptions = useMemo(() => {
    return allTsps.map(tsp => ({ value: tsp.id!.toString(), label: tsp.companyFriendlyName! }))
  }, [allTsps]);

  const allString = formatMessage({
    id: "ALL",
    defaultMessage: "All",
  });


  return (
    <div className="d-flex gap-2 align-items-center my-3">

      {
        (userRole === Role.FleetManager || userRole === Role.FleetUser) &&

        <>
          <div className="header-font me-5">
            <FormattedMessage id="RULES" defaultMessage="Rules" />
            {` (${notificationsCount || 0})`}
          </div>
          <ActionButton
            icon={<img src={addIconPath} className={styles.btn_img} alt="add" />}
            onClick={onClickAdd}
            disabled={disable?.add}
            className={styles["action_btn"]}
          >
            <FormattedMessage id="CREATE" defaultMessage="Add" />
          </ActionButton>

          <ActionButton icon={<EditIcon fill={Color.CIPIA_BLUE} />} onClick={onClickEdit} disabled={disable?.edit} className={styles["action_btn"]}>
            <FormattedMessage id="EDIT" defaultMessage="Edit" />
          </ActionButton>

          <ActionButton icon={<DeleteIcon fill={Color.CIPIA_BLUE} />} onClick={onClickDelete} disabled={disable?.delete} className={styles["action_btn"]}>
            <FormattedMessage id="DELETE" defaultMessage="Delete" />
          </ActionButton>

          <div className={`${styles["seperator"]} mx-2`}></div>

        </>

      }

      {(userRole === Role.CipiaAdministrator) &&
        <>
          <SingleSelectFilter<NotificationsSortFilterParamName>
            context={NOTIFICATIONS_CONTEXT}
            paramName="tspId"
            operator="=="
            placeHolder={allString}
            inputLabel={formatMessage({
              id: "TSP",
              defaultMessage: "TSP",
            })}
            options={tspOptions}
            style={{ minWidth: InputUi.MULTI_STD_WIDTH }}
            includeInUrl
          />
        </>
      }

      {(userRole === Role.CipiaAdministrator || userRole === Role.SpManager) &&
        <>
          <SingleSelectFilter<NotificationsSortFilterParamName>
            context={NOTIFICATIONS_CONTEXT}
            paramName="fleetId"
            operator="=="
            placeHolder={allString}
            inputLabel={formatMessage({
              id: "FLEET",
              defaultMessage: "Fleet",
            })}
            options={fleetsOptions}
            style={{ minWidth: InputUi.MULTI_STD_WIDTH }}
            includeInUrl
          />
        </>
      }

    </div>
  );
}

export default NotificationsTableController;
