import { FormattedMessage } from "react-intl";
import ModalYesNo from "../../ModalYesNo/ModalYesNo";
import { NotificationTableEntry } from "../NotificationsTable";
import { ReactComponent as TrashBigIcon } from "../../../assets/icons/TrashBig.svg";
import useRule from "../../../hooks/useRule";


type DeleteModalProps = {
  show: boolean;
  onClickYes: () => void;
  onClickNo: () => void;
  ruleData: NotificationTableEntry;
};

function DeleteNotificationModal({ show, onClickNo, onClickYes, ruleData }: DeleteModalProps) {
  const { deleteRule, isDeleteLoading } = useRule();
  return (
    <ModalYesNo
      show={show}
      onClickNo={onClickNo}
      onClickYes={async () => {
        await deleteRule(ruleData.Id!);
        onClickYes();
      }}
      onHide={onClickNo}
      isLoading={isDeleteLoading}
      body={
        <div className="d-flex flex-column align-items-center gap-3">
          <TrashBigIcon />
          <FormattedMessage id="RULE.DELETE_QUESTION" defaultMessage="Are you sure you want to delete this rule?" />
        </div>
      }
    />
  );
}

export default DeleteNotificationModal;
