import { AxiosResponse } from "axios";
import { UserManagement as NotificationApiTest } from "../api/UserManagement";
import { RuleManagement as NotificationApi } from "../api/RuleManagement";
import { RuleDto, RuleWithMoreData } from "../api/data-contracts"
import { FilterParam, ServiceGetResult } from "../models/requests/shared.request";
import { addAuthHandling } from "../util/api.util";
import { filterParamsToString } from "../util/filtering.util";
import { getNumberOfPages } from "../util/pagination.util";
import { sortParamsToString } from "../util/sorting.util";

export type NotificationsSortFilterParamName =
  | "fleetId"
  | "tspId"
  | "name"
  | "description"
  | "ruleNotificationTypes"
  | "ruleSchedules"
  | "createdDateTime"
  | "createdUserName";

const notificationApi = new NotificationApi();
addAuthHandling(notificationApi);

const notificationApiTest = new NotificationApiTest();
addAuthHandling(notificationApiTest);

type PagingParams = { pageIndex: number; pageSize: number };
export type NotificationsSortParam = {
  param: NotificationsSortFilterParamName;
  descending?: boolean;
};

export type GetNotificationsParams = {
  paging: PagingParams;
  sorts: NotificationsSortParam[];
  filters: FilterParam<NotificationsSortFilterParamName>[];
};

export type GetNotificationsResult = ServiceGetResult<RuleWithMoreData[]>;

export const notificationService = {
  getNotifications: async ({ paging, sorts, filters }: GetNotificationsParams): Promise<GetNotificationsResult> => {
    const result = await notificationApi.getRules({
      sorts: sorts.length ? sortParamsToString(sorts) : undefined,
      filters: filters.length ? filterParamsToString(filters) : undefined,
      page: paging.pageIndex,
      pageSize: paging.pageSize,
    });

    const header = result.data.header;
    let pageCount: number | undefined = undefined;
    if (header && header.pageSize && header.totalCount !== undefined) {
      pageCount = getNumberOfPages(header.totalCount, header.pageSize);
    }
    return {
      pageCount,
      data: result.data.data,
      totalCount: header?.totalCount,
    };
  },

  addNotification: async (notification: RuleDto): Promise<AxiosResponse> => {
    return await notificationApi.addRule(notification);
  },

  updateNotification: async (ruleId: number, notification: RuleDto): Promise<AxiosResponse> => {
    return await notificationApi.updateRule(ruleId, notification);
  },

  deleteNotification: async (notificationId: number): Promise<AxiosResponse> => {
    return await notificationApi.deleteRule(notificationId);
  },

  getNotification: async (notificationId: number): Promise<AxiosResponse<RuleDto, any>> => {
    return await notificationApi.getRule(notificationId);
  },
};
