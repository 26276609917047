import { UseMutationOptions } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { RuleDto } from "../api/data-contracts";
import { notificationService } from "../services/notification.service";

export const addRuleMutation = (): UseMutationOptions<
  AxiosResponse,
  AxiosError,
  { rule: RuleDto }
> => ({
  mutationKey: ["addRule"],
  mutationFn: ({ rule }: { rule: RuleDto }) => notificationService.addNotification(rule),
});

export const updateRuleMutation = (): UseMutationOptions<
  AxiosResponse,
  AxiosError,
  { rule: RuleDto, ruleId: number }
> => ({
  mutationKey: ["updateRule"],
  mutationFn: ({ rule, ruleId }: { rule: RuleDto, ruleId: number }) => notificationService.updateNotification(ruleId, rule),
});

export const deleteRuleMutation = (): UseMutationOptions<
  AxiosResponse,
  AxiosError,
  { ruleId: number }
> => ({
  mutationKey: ["deleteRule"],
  mutationFn: ({ruleId }: { ruleId: number }) => notificationService.deleteNotification(ruleId),
});