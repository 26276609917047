import { QueryParamsContextProvider, useQueryParamsContext } from "./QueryParamsContext";
import { GetNotificationsParams, NotificationsSortFilterParamName } from "../../services/notification.service";
import { PropsWithChildren } from "react";

import { Constants } from "../../constants";
import { NOTIFICATIONS_CONTEXT } from "./queryParamContextObjects";

export function NotificationsQueryParamsContextProvider({ children }: PropsWithChildren) {
  const initialParams: GetNotificationsParams = {
    filters: [],
    sorts: [],
    paging: { pageIndex: 1, pageSize: Constants.DEFAULT_PAGESIZE },
  };

  return <QueryParamsContextProvider context={NOTIFICATIONS_CONTEXT} initialParams={initialParams}>{children}</QueryParamsContextProvider>;
}

export const useNotificationsQueryParamsContext = () =>
  useQueryParamsContext<NotificationsSortFilterParamName, NotificationsSortFilterParamName>(NOTIFICATIONS_CONTEXT);
