import { CSSProperties, useCallback, useEffect, useState } from "react";
import { FilterOperator } from "../../models/requests/shared.request";
import useFilter from "../../hooks/useFilter";
import { IQueryParamsContext } from "../../context/QueryParamsContext/QueryParamsContext";
import { Options, SingleValue } from "react-select";
import { BaseOption } from "../ReactSelect/ReactSelect";
import SingleSelectInput from "../form/SingleSelectInput/SingleSelectInput";

type SingleSelectFilterProps<TParamName extends string, Option extends BaseOption> = {
  context: React.Context<IQueryParamsContext<any, any>>,
  placeHolder: string;
  paramName: TParamName;
  operator: FilterOperator;
  inputLabel?: string;
  style?: CSSProperties;
  includeInUrl?: boolean;
  options: Options<Option>;
};

const SingleSelectFilter = <TParamName extends string, Option extends BaseOption = BaseOption>({
  context,
  placeHolder,
  paramName,
  operator,
  inputLabel,
  style,
  includeInUrl,
  options,
}: SingleSelectFilterProps<TParamName, Option>) => {
  const onForcedClear = useCallback(() => {
    setSelectedOption(undefined);
  }, []);

  const { upsertOrRemoveFilter, contextValues } = useFilter<TParamName>(
    context,
    paramName,
    operator,
    onForcedClear,
    includeInUrl
  );

  const [selectedOption, setSelectedOption] = useState<SingleValue<Option>>();

  useEffect(() => {
    if (contextValues && contextValues.length > 0)
      setSelectedOption(options.find(x => x.value === contextValues[0]));
  }, [contextValues, options]);

  return (
    <SingleSelectInput inputLabel={inputLabel}
      isClearable={true}
      value={selectedOption}
      style={style} options={options} placeholder={placeHolder}
      onChange={(e: any) => { setSelectedOption(e); upsertOrRemoveFilter(e ? e?.value : undefined) }} />
  );
};

export default SingleSelectFilter;
